<template>
  <v-alert
    :dismissible="dismissible"
    :icon="icon"
    :outlined="enfasis !== 'alto'"
    :text="enfasis === 'medio'"
    :type="type"
    class="text-body-2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- @slot Contenido del alert -->
    <slot />
  </v-alert>
</template>

<script>
export default {
  props: {
    /**
     * Tipo (y color)
     * @values info, success, warning, error
     */
    type: {
      type: String,
      default: 'info',
    },

    /**
     * Énfasis
     * @values bajo, medio, alto
     */
    enfasis: {
      type: String,
      default: 'medio',
    },

    /**
     * Agrega un botón para cerrar el alert
     */
    dismissible: {
      type: Boolean,
      default: false,
    },

    /**
     * Permite asignar un ícono alternativo o
     * deshabilitarlo con `false`
     */
    icon: {
      type: [String, Boolean],
      default: undefined,
    },
  },
}
</script>
