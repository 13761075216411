<template>
  <div
    class="fill-height d-flex align-center justify-center flex-column mx-auto"
    style="height: 80vh"
  >
    <logo-sem-color width="250" />

    <logo-clave-unica style="fill: #1565c0" width="160" class="mr-2 mt-4" />

    <v-progress-linear
      v-if="!error"
      style="width: 300px"
      class="my-2"
      color="#1565C0"
      indeterminate
      rounded
      height="6"
    />

    <div v-if="!error" class="text-caption text--secondary">Iniciando sesión...</div>

    <z-alert v-if="!!error" :icon="false" class="my-8" width="350">
      <div class="text-h6">Error de inicio de sesión</div>
      <div class="mt-1 mb-6">
        {{ error }}
      </div>

      <v-btn outlined to="/" color="primary"> Continuar </v-btn>
    </z-alert>
  </div>
</template>

<script>
import LogoSemColor from '@/components/Icons/LogoSemColor'
import LogoClaveUnica from '@/components/Icons/LogoClaveUnica'
import ZAlert from '@/components/ZAlert'
import { getError } from '@/utils/errors'

export default {
  name: 'Login',
  components: { ZAlert, LogoClaveUnica, LogoSemColor },
  data() {
    return {
      loading: false,
      error: '',
    }
  },
  beforeMount() {
    this.login()
  },
  methods: {
    login() {
      this.$store
        .dispatch('auth/login', {
          provider: 'claveunica',
          ...this.$route.query,
        })
        .then(() => this.$router.push('/dashboard'))
        .catch((error) => {
          error = getError(error)
          this.$router.push( { name: 'login', params: { mensajeDeErrorLogin: error }})
        })
    },
  },
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
</style>
